import React, { Component } from 'react'
import axios from 'axios'
import { navigate } from '@reach/router'
import * as jwt from 'jsonwebtoken'
import { withPrefix } from 'gatsby'

const AuthContext = React.createContext({
  user: null,
  handleLogin: (credentials: any) => {},
  handleSSOLogin: (usertoken: any) => {},
  logOut: () => {},
  purchase: null,
  getPurchase: () => {},
  getUser: () => {},
  setPurchase: (purchase: any) => {},
})

class AuthProvider extends Component<{}, any> {
  constructor(props: any) {
    super(props)
    this.state = { user: this.getUser(), purchase: this.getPurchase() }
  }

  isBrowser = () => typeof window !== 'undefined'

  getUser = () =>
    this.isBrowser() && sessionStorage.getItem('user')
      ? JSON.parse(sessionStorage.getItem('user') || '¨{}')
      : null

  setUser = (user: any) => sessionStorage.setItem('user', JSON.stringify(user))

  setSession = (token: any) => {
    const decodedToken: any = jwt.decode(token, { complete: true })
    if (decodedToken) {
      const expiresAt = new Date(decodedToken.payload.exp * 1000)
      sessionStorage.setItem('access_token', token)
      sessionStorage.setItem('expires_at', expiresAt.toString())
    }
  }

  getPurchase = () =>
    this.isBrowser() && sessionStorage.getItem('purchase')
      ? JSON.parse(sessionStorage.getItem('purchase') || '{}')
      : null

  setPurchase = (purchase: any) => {
    this.setState({ purchase })
    purchase
      ? sessionStorage.setItem('purchase', JSON.stringify(purchase))
      : sessionStorage.removeItem('purchase')
  }

  options = (token: any) => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  }

  handleLogin = async (credentials: any) => {
    const token = await axios
      .post(`${process.env.API_URL}customer/login`, credentials, {})
      .then((response) => {
        return response.data.token
      })
      .catch((error) => {
        throw error
      })

    const user = await axios
      .get(`${process.env.API_URL}customer/get-user-details`, this.options(token))
      .then((response) => response.data.user)
      .catch((error) => {
        throw error
      })

    const purchase = await this.retrievePurchase(token, user.customerId)

    this.setSession(token)
    this.setUser(user)
    this.setPurchase(purchase)
    this.setState({ user, purchase })
  }

  handleSSOLogin = async (usertoken: any) => {
    const token = await axios
      .post(`${process.env.API_URL}customer/ssoLogin`, usertoken, {})
      .then((response) => response.data.token)
      .catch((error) => {
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('access_token')
        sessionStorage.removeItem('expires_at')
        sessionStorage.removeItem('purchase')
        this.setState({ user: null, purchase: null })
        throw error
      })

    const user = await axios
      .get(`${process.env.API_URL}customer/get-user-details`, this.options(token))
      .then((response) => response.data.user)
      .catch((error) => {
        throw error
      })

    const purchase = await this.retrievePurchase(token, user.customerId)

    this.setSession(token)
    this.setUser(user)
    this.setPurchase(purchase)
    this.setState({ user, purchase })
  }

  retrievePurchase = async (token: any, customerId: number) => {
    const purchase = await axios
      .get(`${process.env.API_URL}customer/purchase`, {
        ...this.options(token),
        ...{ params: { customerId, active: true } },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error
      })
    return purchase
  }

  logOut = () => {
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('access_token')
    sessionStorage.removeItem('expires_at')
    sessionStorage.removeItem('purchase')
    this.setState({ user: null, purchase: null })
    navigate(withPrefix('/'))
  }

  componentDidMount() {
    const user = this.getUser()
    const purchase = this.getPurchase()
    if (user) {
      this.setState({ user })
    }
    if (purchase) {
      this.setState({ purchase })
    }
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          user: this.state.user,
          handleLogin: this.handleLogin,
          handleSSOLogin: this.handleSSOLogin,
          logOut: () => this.logOut,
          purchase: this.state.purchase,
          getPurchase: this.getPurchase,
          getUser: this.getUser,
          setPurchase: this.setPurchase,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

export default AuthContext
export { AuthProvider }
