// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-article-tsx": () => import("./../src/pages/article.tsx" /* webpackChunkName: "component---src-pages-article-tsx" */),
  "component---src-pages-authentication-tsx": () => import("./../src/pages/authentication.tsx" /* webpackChunkName: "component---src-pages-authentication-tsx" */),
  "component---src-pages-benefits-tsx": () => import("./../src/pages/benefits.tsx" /* webpackChunkName: "component---src-pages-benefits-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-chefs-tsx": () => import("./../src/pages/chefs.tsx" /* webpackChunkName: "component---src-pages-chefs-tsx" */),
  "component---src-pages-events-tsx": () => import("./../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-generate-password-tsx": () => import("./../src/pages/generate-password.tsx" /* webpackChunkName: "component---src-pages-generate-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoice-data-tsx": () => import("./../src/pages/invoice-data.tsx" /* webpackChunkName: "component---src-pages-invoice-data-tsx" */),
  "component---src-pages-maintenance-tsx": () => import("./../src/pages/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-tsx" */),
  "component---src-pages-membership-process-tsx": () => import("./../src/pages/membership-process.tsx" /* webpackChunkName: "component---src-pages-membership-process-tsx" */),
  "component---src-pages-membership-tsx": () => import("./../src/pages/membership.tsx" /* webpackChunkName: "component---src-pages-membership-tsx" */),
  "component---src-pages-payment-confirmation-tsx": () => import("./../src/pages/payment-confirmation.tsx" /* webpackChunkName: "component---src-pages-payment-confirmation-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-purchase-not-allowed-tsx": () => import("./../src/pages/purchase-not-allowed.tsx" /* webpackChunkName: "component---src-pages-purchase-not-allowed-tsx" */),
  "component---src-pages-registration-tsx": () => import("./../src/pages/registration.tsx" /* webpackChunkName: "component---src-pages-registration-tsx" */),
  "component---src-pages-restaurants-tsx": () => import("./../src/pages/restaurants.tsx" /* webpackChunkName: "component---src-pages-restaurants-tsx" */),
  "component---src-pages-success-membership-message-tsx": () => import("./../src/pages/success-membership-message.tsx" /* webpackChunkName: "component---src-pages-success-membership-message-tsx" */),
  "component---src-pages-update-payment-tsx": () => import("./../src/pages/update-payment.tsx" /* webpackChunkName: "component---src-pages-update-payment-tsx" */)
}

